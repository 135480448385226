import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [2,6,4,5];

export const dictionary = {
		"/(app)": [8,[2],[3]],
		"/(auth)/auth": [23,[6]],
		"/(auth)/auth/federated-signout": [~24,[6]],
		"/(app)/logger": [9,[2],[3]],
		"/public/verifications/simple/create": [26,[7]],
		"/public/verifications/simple/[id]": [~25,[7]],
		"/(app)/settings": [10,[2,4],[3]],
		"/(app)/settings/api-keys": [~11,[2,4],[3]],
		"/(app)/settings/audit": [~12,[2,4],[3]],
		"/(app)/settings/doc-templates": [~13,[2,4],[3]],
		"/(app)/settings/roles": [14,[2,4],[3]],
		"/(app)/settings/users": [15,[2,4],[3]],
		"/tally": [27],
		"/(app)/verifications": [~16,[2],[3]],
		"/(app)/verifications/create": [~18,[2],[3]],
		"/(app)/verifications/create/uploading": [19,[2],[3]],
		"/(app)/verifications/simple/create": [21,[2],[3]],
		"/(app)/verifications/simple/[id]": [~20,[2],[3]],
		"/(app)/verifications/[id]": [~17,[2],[3]],
		"/(app-printable)/verifications/[id]/print": [~22,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';