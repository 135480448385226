import { PUBLIC_SENTRY_DSN, PUBLIC_SENTRY_ENV } from "$env/static/public";
import { type Options } from "@sentry/types";

const allowedEnvs = ["preview", "production"];
const isSentryEnabled = allowedEnvs.includes(PUBLIC_SENTRY_ENV);
export const DEFAULT_SENTRY_OPTIONS: Options = {
	dsn: isSentryEnabled ? PUBLIC_SENTRY_DSN : undefined,
	tracesSampleRate: 1.0,
	environment: PUBLIC_SENTRY_ENV,
	enabled: isSentryEnabled
};
